import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

const NotAdmin = ({ history }: any) => {
  const route = useHistory();

  const onBack = () => route.push(`/signOut`);

  return (
    <div>
      <Result
        status="404"
        title="You need to be Admin in Zumpul for use this Application"
        subTitle="Please request access to the Zumpul administrator."
        extra={
          <Button onClick={onBack} type="primary">
            Back to login
          </Button>
        }
      />
    </div>
  );
};

export default NotAdmin;
