import './styles.scss';
import React from 'react';
import { useGoogleApi } from 'react-gapi';
import { useDispatch } from 'react-redux';
import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import Navbar from 'components/navbar/navbar';
import { Button, Typography } from 'antd';

const NotInstallation = ({ history }: any) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();

  const onBack = () => logout(dispatch, auth);

  return (
    <div>
      <Navbar brand avatar={false} />
      <div className="result-wrapper">
        <div style={{ width: 450, maxWidth: '100%' }} className="text-center">
          <Typography.Title level={2}>
            Application not installed in Google Marketplace
          </Typography.Title>
          <div className="mt-2 text-center">
            <Button size="large" type="primary">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://gsuite.google.com/marketplace/app/zumpul_email_signature_marketing_tool/394696046694"
              >
                Get in Marketplace
              </a>
            </Button>
          </div>

          <div className="mt-2 text-center">
            <Button onClick={onBack} size="large" type="default">
              Retry Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotInstallation;
