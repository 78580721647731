import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

const NotAccess = ({ history }: any) => {
  const route = useHistory();

  const onBack = () => route.push(`/signOut`);

  return (
    <div>
      <Result
        status="403"
        title="this message is being display due to the following possible cause:"
        subTitle={
          <div>
            <div>
              <b>*</b> The installations process is not complete. to complete
              the process clic on 'go to install', you must be a super admin in
              Gsuit.
            </div>
            <div>
              <b>*</b> Access from a user that exists on Google's servers.
            </div>
          </div>
        }
        extra={
          <Button onClick={onBack} type="primary">
            Back to login
          </Button>
        }
      />
    </div>
  );
};

export default NotAccess;
