import { resetAuth, setAuthenticate } from 'redux/actions/auth.action';
import logout from '../config/google/logout';

const actionLogin = async ({
  code,
  profile,
  dispatch,
  history,
  auth,
}: IProps) => {
  const { access_token, ...rest } = profile;
  await logout(dispatch, auth);
  switch (code) {
    case 'NOT_REGISTERED':
      dispatch(
        setAuthenticate({
          ...rest,
          loading: false,
          google: { access_token },
        }),
      );
      return history.replace('/pricing');
    case 'INVALID_ACCESS':
      dispatch(resetAuth());
      return history.push('/not-admin');
    case 'USER_NOT_FOUND':
      dispatch(resetAuth());
      return history.push('/not-access');
    case 'INSTALLATION_REQUIRED':
      dispatch(
        setAuthenticate({
          ...rest,
          loading: false,
        }),
      );
      return history.push('/not-installation');
  }
};

interface IProps {
  code:
    | 'NOT_REGISTERED'
    | 'INVALID_ACCESS'
    | 'USER_NOT_FOUND'
    | 'INSTALLATION_REQUIRED';
  profile: {
    access_token: string;
    [key: string]: unknown;
  };
  history: any;
  dispatch: any;
  auth?: any;
}

export default actionLogin;
