import React from 'react';
import useStateRedux from 'hooks/useStateRedux';
import Loading from 'components/loading/loading';
import { Redirect, Route } from 'react-router-dom';

interface IProps {
  component: any;
  path: string;
  isAdmin?: boolean;

  [key: string]: unknown;
}

const PrivateGoogleRoute = ({
  component: Component,
  isAdmin = true,
  ...rest
}: IProps) => {
  const { google, loading, token }: any = useStateRedux('auth');

  if (loading) return <Loading />;
  if (!!token && isAdmin) return <Redirect to="/" />;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        google.access_token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signOut" />
        )
      }
    />
  );
};

export default PrivateGoogleRoute;
